import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-wrapper.js";
import SEO from '@/components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Tenets" mdxType="SEO" />
    <h1>{`Tenets`}</h1>
    <ol>
      <li parentName="ol">{`Minimal Government`}</li>
      <li parentName="ol">{`Maximum Freedom`}</li>
      <li parentName="ol">{`Anti-Political Correctness`}</li>
      <li parentName="ol">{`Anti-Drug War`}</li>
      <li parentName="ol">{`Closed Borders`}</li>
      <li parentName="ol">{`Anti-Racial Guilt`}</li>
      <li parentName="ol">{`Anti-Racism`}</li>
      <li parentName="ol">{`Pro-Free Speech (1st Amendment)`}</li>
      <li parentName="ol">{`Pro-Gun Rights (2nd Amendment)`}</li>
      <li parentName="ol">{`Glorifying the Entrepreneur`}</li>
      <li parentName="ol">{`Venerating the Housewife`}</li>
      <li parentName="ol">{`Reinstating a Spirit of Western Chauvinism`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      